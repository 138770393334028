import styled from "styled-components"

export const StyledJobOffer = styled.section`
    .jobOffer {
        &__wrapper {
            display: flex;
            flex-direction: column;

            @media ${props => props.theme.sizes.laptop} {
                flex-direction: row;
            }
        }
        &__back {
            color: ${props => props.theme.colors.secondary};
            font-family: ${props => props.theme.fonts.primary};
            text-decoration: none;

            &:hover {
                color: ${props => props.theme.colors.primary};
            }
        }
        &__column {
            padding: 2rem 0;

            @media ${props => props.theme.sizes.laptop} {
                width: 50%;
                padding: 3rem 0;
            }

            &--left {
                padding-left: 5vw;
                padding-right: 5vw;

                @media ${props => props.theme.sizes.laptop} {
                    padding-left: 10vw;
                    padding-right: 2rem;
                }


                ul {
                    padding-left: 1.2rem;
                }
            }

            &--right {
                padding-right: 5vw;
                padding-left: 5vw;

                @media ${props => props.theme.sizes.laptopM} {
                    padding-right: 12.5vw;
                    padding-left: 8rem;
                    margin-top: 4.7rem;
                }
            }
        }
        &__description {
            font-family: ${props => props.theme.fonts.primary};
        }
        &__image {
            margin: 2rem 0;
        }
        &__sticky {
            height: 100%;
        }
        &__stickyWrapper {
            font-family: ${props => props.theme.fonts.primary};
            background: ${props => props.theme.colors.gray};
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 4rem 2rem;


            @media ${props => props.theme.sizes.laptopM} {
                padding: 4rem 4rem;
            }
        }
        &__salary {
            color: ${props => props.theme.colors.text};
            font-size: 1.8rem;
            font-weight: 800;
            margin-top: 1rem;
        }
        &__form {
            .form {
                &__mail {
                    padding: .6rem;
                }
                input[type="button"] {
                    color: blue;
                    padding: 2rem;
                    background: yellow !important;
                    font-size: 1.2rem;
                }
                &__CV {
                    padding: .6rem 0;

                    &::-webkit-file-upload-button {
                        background: ${props => props.theme.colors.primary};
                        border: none;
                        color: #fff;
                        font-family: ${props => props.theme.fonts.primary};
                        padding: .6rem 1.1rem;
                        font-size: 1.2rem;
                        margin-right: 1rem;
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 1rem;

                        @media ${props => props.theme.sizes.laptop} {
                            flex-direction: row;
                        }
                    }


                }
                &__policy {
                    a {
                        color: ${props => props.theme.colors.text};
                        font-size: 0.75rem;
                        text-decoration: none;

                        @media ${props => props.theme.sizes.laptop} {
                            font-size: 0.9rem;
                        }
                    }
                }
                &__submit {
                    margin: 2rem 0;
                    border: none;
                    background: none;

                    a {
                        font-size: 1.7rem;
                        font-weight: 700;
                    }
                }
            }

            label {
                margin-bottom: 0.5rem;
                color: #000;
                font-weight: 600;
            }

            input {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin: .7rem 0 1.8rem;
            }

        }

    }
`
