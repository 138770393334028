import React from "react"
import { Link } from "gatsby"
import styled from "styled-components";

const StyledButton = styled.div`
  cursor: pointer;
  display: ${props => props.display || 'block'};

  a {
      font-size: 1.5rem;
      font-weight: ${props => props.fontWeight || '400'};
      font-family: ${props => props.theme.fonts.primary};
      color: ${props => props.color || "#F19206"};
      transition: all 0.3s ease 0s;
      text-decoration: none;

      @media ${props => props.theme.sizes.laptop} {
        font-size: 1.2rem;
      }

      @media ${props => props.theme.sizes.laptopL} {
        font-size: ${props => props.size || '1rem'};
    }
  }
  &:hover {
    a {
      transition: all 0.3s ease 0s;
      color: ${props => props.hoverColor || props.color};
    }

  }
  &:focus {
    text-decoration: none;
    outline: none;
  }
`
const GTButton = (props) => {


  return (
    <StyledButton className="button" display={props.display} size={props.fontSize} color={props.color} hoverColor={props.hoverColor} fontWeight={props.fontWeight}>
        <Link to={props.anchor}>
           <span>{props.text}</span>
           <span className="arrow">  ></span>
           <span className="arrow">></span>
        </Link>
    </StyledButton>
  )
}

export default GTButton
