import React, { useState, useEffect } from "react"

import GTButton from "../../atoms/Button"
import "./form.scss"

import { Link } from "gatsby"
const axios = require("axios")

export default function JobAplication(props) {
  const [formElements, setFormData] = useState("")
  const [formMessage, setFormMessage] = useState("")
  const [formMessageApear, setFormMessageApear] = useState("")
  const fileInput = React.createRef();

  useEffect(() => {
      const copyFormData = { ...formElements }
      copyFormData['offerName'] = props.position
      setFormData(copyFormData)
  }, []);


  const handleInput = e => {
    const copyFormData = { ...formElements }
    copyFormData[e.target.name] = e.target.value
    setFormData(copyFormData)


  }

  const handleInputFile = e => {
    const copyFormData = { ...formElements }
    copyFormData[e.target.name] = fileInput.current.files[0]
    setFormData(copyFormData)


  }

  const sendData = async e => {
    e.persist()
    e.preventDefault()

    let formData = new FormData()


    formData.set("offerName", formElements.offerName)
    formData.set("emailCV", formElements.emailCV)
    formData.set("fileCV", formElements.fileCV)

    axios({
      method: "POST",


      url:
        "https://dev.grupataka.pl/grupataka/wp-json/contact-form-7/v1/contact-forms/2924/feedback?_locale=user",
      data: formData,
    }).then(
      response => {
        setFormMessage("Twoja wiadomość została wysłana")
        setFormMessageApear("show")
        e.target.reset()
      },
      error => {
        console.log(error)
        setFormMessage(
          "Twoja wiadomość NIE została wysłana. Spróbuj jeszcze raz"
        )
        setFormMessageApear("show")
      }
    )
  }

  return (
    <div className="JobForm">
      <form className="jobOffer__form" onSubmit={sendData}>

            <label>Twój adres email*</label>
            <input className="form__mail" type="email" name="emailCV" onChange={handleInput} />
            <label>Załącz CV*</label>
            <input className="form__CV" type="file" name="fileCV" onChange={handleInputFile} ref={fileInput} />
            <div className="form__policy">
            <Link to='/polityka-prywatnosci/'>Przeczytaj naszą politykę prywanotści > ></Link>
            </div>
            <button className="form__submit" name="submit" type="submit">
                <GTButton  type="submit" text="Aplikuj" hoverColor='#000'/>
            </button>

            <div id="SendMessage" className={formMessageApear + " message"}>
              {formMessage}
            </div>

      </form>
    </div>
  )
}
