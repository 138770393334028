import React from "react"
import Layout from "../../components/layout"
import { SEO } from "../../components/seo"

import Sticky from "react-stickynode"
import { Link } from "gatsby"
import GTButton from "../../components/atoms/Button"

import { StyledJobOffer } from "./StyledJobOffer"
import JobAplication from "../../components/organisms/form/JobForm"



const JobOffer = props => {
  const Title = props.pageContext.title
  const ACF = props.pageContext.ACF_jobOffer
  const SEO = props.pageContext.seo

  console.log(Title)


  const buttonBack = "<< Wróc do ofert pracy"

  const SeoUrl = props.pageContext.uri.replace('/oferty_pracy/', '/kariera/')



  return (
    <Layout seoData={SEO} url={SeoUrl} seoUrl={SeoUrl}>
      <StyledJobOffer>

            <div className="jobOffer__wrapper">
              <div className="jobOffer__column jobOffer__column--left">
                <Link className="jobOffer__back" to='/kariera#oferty'>{buttonBack}</Link>
                <h1>{Title}</h1>

                {ACF.jobDescription && (
                  <>
                    <h2>Opis stanowiska</h2>
                    <div className="jobOffer__description"
                    dangerouslySetInnerHTML={{ __html: ACF.jobDescription }}
                    />
                  </>
                 )}

                {ACF.niceToHave && (
                  <>
                  <h2>Czym będziesz się zajmować?</h2>
                  <div className="jobOffer__description"
                  dangerouslySetInnerHTML={{ __html: ACF.niceToHave }}
                  />
                  </>
                 )}
                 {ACF.requirements && (
                   <>
                    <h2>Czego od Ciebie oczekujemy?</h2>
                    <div className="jobOffer__description"
                    dangerouslySetInnerHTML={{ __html: ACF.requirements }}
                    />
                   </>
                 )}
                 {ACF.requirements && (
                   <>
                    <h2>Co proponujemy?</h2>
                    <div className="jobOffer__description"
                    dangerouslySetInnerHTML={{ __html: ACF.weOffer }}
                    />
                   </>

                 )}
              </div>
              <div id='RightColumn' className="jobOffer__column jobOffer__column--right">
                <Sticky
                  top={100}
                  bottomBoundary="#RightColumn"
                  className="jobOffer__sticky"
                >
                  <div className="jobOffer__stickyWrapper">
                    <div className="jobOffer__salary">
                      {ACF.range}
                    </div>
                    <JobAplication position={Title} />
                  </div>
                </Sticky>
              </div>
            </div>

      </StyledJobOffer>
    </Layout>

  )
}
export default JobOffer

export const Head = props => {
  return (
   <SEO title={props.pageContext.seo.title} description={props.pageContext.seo.opengraphDescription} image={props.pageContext.seo?.opengraphImage?.localFile.childImageSharp.fixed.src} pathname={props.pageContext.seo.opengraphUrl} />
  )
}
